import Button from './common/Button';
import Input from './common/Input';

const ItemInfo = function () {
  return (
    <div className="w-full h-screen flex flex-col text-[12px] font-medium bg-reborn-gray0 gap-[12px]">
      <div className="w-full px-[12px] flex flex-col bg-reborn-white py-[10px]">
        <h1 className="font-medium text-[14px] leading-[21px]">상품 정보</h1>
        <div className="w-full flex flex-row gap-[10px]">
          <img
            src="/assets/images/sample.png"
            alt="sample"
            className="w-[90px] h-[90px]"
          />
          <div>
            <div>추억 쿠션</div>
            <div className="font-bold">100,000원</div>
          </div>
        </div>
      </div>
      <div className="w-full px-[12px] flex flex-col gap-[4px] bg-reborn-white py-[10px]">
        <div className="font-medium text-[14px] leading-[21px] flex flex-row">
          <h1 className="flex-1">회원 정보</h1>
          <button className="text-reborn-gray4 text-[12px]" disabled>
            수정
          </button>
        </div>
        <div>· 김보호 010-1234-5678</div>
      </div>
      <div className="w-full px-[12px] flex flex-col bg-reborn-white py-[10px] gap-[8px]">
        <h1 className="font-medium text-[14px] leading-[21px]">주소</h1>
        <div className="w-full flex flex-row gap-[8px]">
          <Input
            placeholder="우편번호"
            className="!text-[12px] !px-[12px] !py-[8px]"
          />
          <Button className="!text-[12px] !px-[12px] !py-[8px]" disabled>
            주소 찾기
          </Button>
        </div>
        <Input
          placeholder="주소"
          className="!text-[12px] !px-[12px] !py-[8px]"
        />
        <Input
          placeholder="상세주소"
          className="!text-[12px] !px-[12px] !py-[8px]"
        />
      </div>
      <div className="w-full px-[12px] flex flex-col bg-reborn-white py-[10px] gap-[8px]">
        <h1 className="font-medium text-[14px] leading-[21px]">결제 정보</h1>
        <div className="w-full flex flex-row justify-between">
          <div>총 결제 금액</div>
          <div className="font-semibold text-reborn-orange3">100,000원</div>
        </div>
      </div>
      <div className="w-full px-[12px] flex flex-col bg-reborn-white py-[10px] gap-[8px]">
        <h1 className="font-medium text-[14px] leading-[21px]">결제 방식</h1>
        <div className="flex flex-row items-center gap-[4px]">
          <button className="w-[15px] h-[15px] bg-reborn-white rounded-full border-[4px] border-reborn-gray8" />{' '}
          카카오페이
        </div>
        <div className="flex flex-row items-center gap-[4px]">
          <button className="w-[15px] h-[15px] bg-reborn-gray2 rounded-full" />{' '}
          네이버페이
        </div>
        <div className="flex flex-row items-center gap-[4px]">
          <button className="w-[15px] h-[15px] bg-reborn-gray2 rounded-full" />{' '}
          신용/체크카드
        </div>
      </div>
      <div className="w-full px-[12px] flex flex-col bg-reborn-white py-[10px] gap-[8px]">
        <h1 className="font-medium text-[14px] leading-[21px] flex flex-row items-center gap-[8px]">
          <input type="checkbox" />
          주문 내용 확인 및 결제 동의
        </h1>
        <div className="text-reborn-gray3 font-normal">
          <div>개인 정보 제3자 정보 제공 동의</div>
          <div>결제대행 서비스 이용약관 동의</div>
        </div>
      </div>
      <div className="w-full px-[12px] flex flex-col">
        <Button className="w-full">100,000 결제하기</Button>
      </div>
    </div>
  );
};

export default ItemInfo;
