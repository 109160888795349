const mainTerms = [
  {
    title: '택배 배송',
    terms: [
      '· 무지개리본은 전 상품 무료배송입니다. (일부 상품 및 도서산간지역 제외)',
      '· 브랜드 및 상품에 따라 무지개리본 직배송과 브랜드(입점 업체) 배송으로 나뉩니다.',
      '· 결제 확인 후 평균 1~3일 소요 됩니다. (도서산간 지역 등은 3~5일이 더 소요될 수 있습니다.)',
    ],
  },
  {
    title: '교환/반품',
    terms: [],
    termTable: [
      {
        key: '배송사',
        value: '롯데 택배',
      },
      {
        key: '배송비',
        value:
          '왕복 6,000원(도서산간 및 일부 지역 추가비용 발생)\n 구매자 책임 사유일 경우에만 배송비가 발생하며 요청상품의 수량에 따라서 상이할 수 있습니다.',
      },
      {
        key: '보내실 곳',
        value: '(39311)경상북도 구미시 형곡로 153, 2층 202호 미래빌딩',
      },
    ],
  },
  {
    title: '교환/반품 시 주의사항',
    terms: [
      '· 상품 수령 후 7일 이내 교환/반품을 요청해야 합니다.',
      '· 자동회수 접수 시 영업일 기준 2~3일 내에 택배기사님 연락 후 방문하여 회수 진행됩니다.',
      '· 자동회수 미지원 업체 및 직접발송의 경우 수령하신 택배사로 착불(신용)로 반품 접수하여 발송 부탁드리겠습니다.',
      '· 수령하신 택배사가 아닌 다른 택배사로 발송하시는 경우(신규 택배 접수 포함) 추가 비용이 발생 할 수 있습니다.',
      '· 반품 주소지는 브랜드(업체)마다 다르므로 확인 후 각각 보내셔야 합니다.',
      '· 무지개리본 직배송과 브랜드(입점 업체) 배송 상품은 교차 교환할 수 없습니다.',
      '· 상품의 내용이 표시, 광고 내용과 다르거나 계약 내용이 다를 경우 상품을 공급받은 날부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일내 청약 철회를 할 수 있습니다.',
      '· 상품을 반환받은 날로부터 영업일 기준 3일 내 지급받은 대금을 환급합니다.',
    ],
  },
  {
    title: '교환/반품이 불가능한 경우',
    terms: [
      '· 반품 요청 기간(수령 후 7일 이내)이 경과한 경우',
      '· 상품을 사용(또는 착용) 혹은 훼손하여 재판매가 어려울 정도로 상품가치가 현저히 감소한 경우',
      '· 상품의 택(Tag) 또는 라벨 제거, 분리, 분실, 훼손하거나 세트 구성품을 누락한 경우',
      '· 브랜드 박스를 분실하거나 훼손(오염)한 경우(상품 확인 목적으로 단순 개봉한 경우는 제외)',
      '· 주문/제작 상품의 경우, 상품의 제작이 이미 진행된 경우',
    ],
  },
];

interface RowProps {
  title: string;
  terms: string[];
  termTable?: { key: string; value: string }[];
}

const TermRow = function ({ termTable, terms, title }: RowProps) {
  return (
    <div className="w-full text-reborn-gray8 text-[12px] flex flex-col gap-[8px]">
      <h1 className="text-[14px] leading-[21px] font-medium">{title}</h1>
      {!!terms.length && (
        <ul className="w-full flex flex-col gap-[4px]">
          {terms.map(term => {
            return (
              <li className="text-[12px] leading-[21px] font-medium text-reborn-gray4">
                {term}
              </li>
            );
          })}
        </ul>
      )}
      {termTable?.map(({ key, value }) => {
        return (
          <div className="w-full flex flex-row">
            <div className="flex-[1]">{key}</div>
            <div className="flex-[7]">{value}</div>
          </div>
        );
      })}
    </div>
  );
};

const Terms = function () {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="shadow-xl w-[668px] px-[50px] pt-[40px] pb-[50px] flex flex-col gap-[20px]">
        <div className="w-full flex flex-col gap-[20px]">
          <img
            src="/assets/images/ic_logo_white.png"
            className="w-[150px] h-auto"
            alt="Logo"
          />
          <div className="font-medium text-[14px] leading-[21px]">환불정책</div>
          <div className="w-full border-[1px] border-reborn-gray0" />
        </div>
        <div className="w-full flex flex-col gap-[30px]">
          {mainTerms.map(({ terms, title, termTable }) => {
            return (
              <TermRow
                terms={terms}
                title={title}
                termTable={termTable}
                key={`${title}`}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Terms;
