const ItemDetail = function () {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="shadow-xl w-[668px] px-[50px] pt-[40px] pb-[50px] flex flex-col gap-[20px]">
        <div className="w-full flex flex-col gap-[20px]">
          <img
            src="/assets/images/ic_logo_white.png"
            className="w-[150px] h-auto"
            alt="Logo"
          />
          <div className="font-medium text-[14px] leading-[21px]">
            결제 상품/서비스 상세 내용
          </div>
          <div className="w-full border-[1px] border-reborn-gray0" />
        </div>
        <div className="w-full flex flex-col gap-[20px]">
          <img
            src="/assets/images/sample.png"
            alt="상품 이미지"
            className="w-full h-[458px]"
          />
          <div className="w-full flex flex-row justify-between items-center font-medium">
            <div>
              <h1 className="text-[14px] leading-[21px]">추억 쿠션</h1>
              <div className="text-[12px] flex flex-row items-center gap-[4px]">
                <img
                  src="/assets/images/star.png"
                  alt="star"
                  className="w-[13px] h-[13px]"
                />
                4.68 <span className="text-reborn-gray4">(323건)</span>
              </div>
            </div>
            <div>100,000원</div>
          </div>
          <div className="text-[12px] leading-[18px] flex flex-col gap-[2px]">
            <div>
              물건에는 추억이 담겨있고, 그 중에서도 반려동물과 함께한 시간은
              더욱 소중합니다.
            </div>
            <div>
              반려동물 옷으로 추억의 쿠션을 제작해 드리는 서비스는 반려동물과의
              특별한 순간을 영원히 간직할 수 있습니다.
            </div>
            <div>
              이 서비스는 반려동물의 옷을 재활용하여, 그 고유의 느낌과 향기를
              그대로 간직한 채로 아름다운 쿠션으로 재탄생시킵니다.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemDetail;
